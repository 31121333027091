import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const ITunesBanner = (props) => {
    return (
        <StaticQuery
            query={graphql`query itunes {
  fileName: file(relativePath: {eq: "apple-podcasts-banner.png"}) {
    childImageSharp {
      gatsbyImageData(width: 182, quality: 100, layout: CONSTRAINED)
    }
  }
}
`}
            render={data => (
                <Link
                    to={props.url}
                >
                    <GatsbyImage
                        image={data.fileName.childImageSharp.gatsbyImageData}
                        style={{width: "182px", height: "44px"}}
                        alt="Listen on Apple Podcasts" />
                </Link>
            )}
        />
    );
}