import React from "react";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import classNames from "classnames/bind";
import styles from "./People.module.scss";

export const People = ({ className, people, title }) => {
    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        className
    );

    return (
        <StaticQuery
            query={graphql`query PersonPlaceholder {
                fileName: file(
                    relativePath: {eq: "podcasts/host-placeholder.png"}
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 134,
                            quality: 100,
                            layout: CONSTRAINED
                        )
                    }
                }
            }`}

            render={data => (
                <div className={classes}>
                    {title && title.length > 0 &&
                        <h3>{title}</h3>
                    }
                    <div className={styles.people}>
                        {people.map(person =>
                            <div
                                key={person.name}
                                className={cx("person", person.name)}
                            >
                                <GatsbyImage
                                    image={
                                        person.image && person.image !== null ?
                                            person.image.childImageSharp.gatsbyImageData
                                        :
                                            data.fileName.childImageSharp.gatsbyImageData
                                    }
                                    className={styles.image}
                                    alt={person.friendlyName} 
                                />
                                <h4 className={styles.name}>
                                    {person.friendlyName}
                                </h4>
                            </div>
                        )}
                    </div>
                </div>
            )}
        />
    );
}


    
People.propTypes = {
    className: PropTypes.string,
    people: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string
};