import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout/Layout";
import PodcastHeader from "../components/podcast-header/PodcastHeader";
import PodcastFooter from "../components/podcast-footer/PodcastFooter";
import { People } from "../components/people/People";
import { Share } from "../components/share/Share";
import ScrollProgress from "../components/scroll-progress/ScrollProgress";

import classNames from "classnames/bind";
import styles from "./Post.module.scss";

export const query = graphql
    `query PodcastsByID(
        $slug: String!,
        $type: String!,
        $cat: String!
    ) {
        site {
            siteMetadata {
                s3bucket
                siteUrl
            }
        }

        related: allMdx(
            limit: 5
            filter: {
                frontmatter: {
                    category: {eq: $cat},
                    type: {eq: $type}
                },
                slug: {ne: $slug}
            }
            sort: {
                fields: [frontmatter___date],
                order: DESC
            }
        ) {
            nodes {
                slug
                frontmatter {
                    category
                    date(formatString: "MMM Do, YYYY")
                    dateSimple: date(formatString: "YYYY-MM-DD")
                    released(formatString: "MMM Do, YYYY")
                    releasedSimple: released(formatString: "YYYY-MM-DD")
                    excerpt
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                width: 254,
                                quality: 80,
                                formats: [WEBP, JPG],
                                layout: CONSTRAINED
                            )
                        }
                    }
                    length
                    title
                    type
                    media
                }
            }
        }

        mdx(slug: {eq: $slug}) {
            body
            slug
            frontmatter {
                author {
                    friendlyName
                    page
                }
                image {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 80,
                            layout: FULL_WIDTH
                        )
                        fixed(
                            toFormat: JPG
                            cropFocus: ATTENTION
                            height: 800
                            width: 1200
                            jpegQuality: 100
                        ) {
                            src
                        }
                    }
                }
                title
                date(formatString: "MMM Do, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD HH:mm z")
                type
                category
                excerpt
                tags
                series {
                    title
                    summary
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                width: 440,
                                quality: 80,
                                layout: CONSTRAINED
                            )
                        }
                    }
                    color {
                        background
                        textIsLight
                    }
                    explicit
                    itunes
                    subscribe {
                        name
                        url
                    }
                }
                season
                episode
                length
                file {
                    url
                    size
                }
                episodeType
                hosts {
                    name
                    friendlyName
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                width: 134,
                                quality: 100,
                                layout: CONSTRAINED
                            )
                        }
                    }
                }
            }
        }
    }
`

const Podcast = ({ data, location }) => {

    const { body, frontmatter, slug } = data.mdx;

    const cx = classNames.bind(styles);

    const contentClasses = cx(
        "content",
        frontmatter.type
    );

    return (
        <Layout
            location={location}
            type={frontmatter.type}
            title={frontmatter.title}
        >
            <GatsbySeo
                openGraph={{
                    title: frontmatter.title,
                    description: frontmatter.excerpt,
                    url: location.href,
                    type: "article",
                    article: {
                        publishedTime: frontmatter.dateSimple,
                        section: frontmatter.category,
                        authors: [
                            frontmatter.type !== "podcast" && data.site.siteMetadata.siteUrl + frontmatter.author.page,
                        ],
                        tags: frontmatter.tags.map(tag => { return tag }),
                    },
                    images: [
                        {
                            url: data.site.siteMetadata.siteUrl + frontmatter.image.childImageSharp.fixed.src,
                            width: 1200,
                            height: 800,
                            alt: frontmatter.title,
                        },
                    ],
                }}
            />

            <ScrollProgress />

            <PodcastHeader
                frontmatter={frontmatter}
                s3bucket={data.site.siteMetadata.s3bucket}
            />

            <div className={contentClasses}>
                <div className={styles.primary}>
                    <div className={styles.body}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>
                </div>

                <div className={styles.secondary}>
                    <Share
                        className={styles.share}
                        title="Share this episode"
                        currentUrl={location.href}
                        pageTitle={frontmatter.title}
                    />

                    <People
                        title="Credits"
                        people={frontmatter.hosts}
                    />
                </div>
            </div>

            <PodcastFooter
                slug={slug}
                series={frontmatter.series}
                related={data.related.nodes}
            />
        </Layout>
    );
}

export default Podcast;