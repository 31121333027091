import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { ITunesBanner } from "../itunes-banner/ITunesBanner";

import classNames from "classnames/bind";
import styles from "./PodcastFooter.module.scss";

class PodcastFooter extends React.Component {
    render() {
        const {
            related,
            series,
            slug
        } = this.props;

        const cx = classNames.bind(styles);

        const theme = series.color.textIsLight === true ? "light" : "dark";

        const classes = cx(
            "wrapper",
            theme
        );

        return (
            <div
                className={classes}
                style={{
                    backgroundColor: series.color.background
                }}
            >
                <div className={styles.content}>
                    <div className={styles.more}>
                        <h3>More episodes</h3>
                        <ul className={styles.list}>
                            {related.map(podcast =>
                                {return slug !== podcast.slug &&
                                    <li
                                        key={podcast.slug} 
                                        className={styles.item}
                                    >
                                        <Link
                                            className={styles.link} 
                                            to={`/${podcast.slug}`}
                                        >
                                            <h4>{podcast.frontmatter.title}</h4>
                                        </Link>
                                        <p className={styles.meta}>
                                            <time className={styles.date} dateTime={podcast.frontmatter.dateSimple}>{podcast.frontmatter.date}</time>
                                            <span className={styles.length}>{podcast.frontmatter.length}</span>
                                        </p>
                                    </li>
                                }
                            )}
                        </ul>
                    </div>
                    
                    <div className={styles.info}>
                        <h3 className={styles.title}>{series.title}</h3>
                        <div className={styles.podcastContent}>
                            <div className={styles.cover}>
                                <GatsbyImage
                                    image={series.image.childImageSharp.gatsbyImageData}
                                    alt={series.title}
                                />
                            </div>
                            <div className={styles.podcastCopy}>
                                <p className={styles.description}>
                                    {series.summary}
                                </p>
                                <div className={styles.subscribe}>
                                    <ITunesBanner
                                        url={series.itunes}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default PodcastFooter;