import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Tags } from "../tags/Tags";
import { Breadcrumbs } from "../breadcrumbs/Breadcrumbs";

import classNames from "classnames/bind";
import styles from "./PodcastHeader.module.scss";

class PodcastHeader extends React.Component {
    render() {
        const {
            frontmatter,
            s3bucket
        } = this.props;

        const cx = classNames.bind(styles);

        const theme = frontmatter.series.color.textIsLight === true ? "light" : "dark";

        const classes = cx(
            "wrapper",
            theme
        );

        return (
            <div
                className={classes}
                style={{backgroundColor: frontmatter.series.color.background}}
            >
                <div className={styles.content}>
                    <div className={styles.copy}>
                        <Breadcrumbs
                            className={styles.breadcrumb}
                            type={"podcast"}
                            category={frontmatter.category}
                        />
                        <p className={styles.series}>{frontmatter.series.title}</p>
                        <h1 className={styles.title}>
                            {frontmatter.season !== "0" ?
                                <React.Fragment>
                                    {frontmatter.season && frontmatter.episode &&
                                        <span>S{frontmatter.season}E{frontmatter.episode}: </span>
                                    }
                                </React.Fragment>
                            :
                                <span>BONUS: </span>
                            }
                            {frontmatter.title}
                        </h1>

                        <p className={styles.date}>
                            <time datetime={frontmatter.dateSimple}>{frontmatter.date}</time>
                        </p>

                        <audio className={styles.audio} controls>
                            <source src={`${s3bucket}${frontmatter.file.url}`} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>

                        <p className={styles.excerpt}>{frontmatter.excerpt}</p>
                        {frontmatter.tags && frontmatter.tags.length > 0 &&
                            <Tags
                                theme={theme}
                                tags={frontmatter.tags}
                                title="Tags"
                            />
                        }
                    </div>
                    <div className={styles.cover}>
                        <GatsbyImage
                            image={frontmatter.series.image.childImageSharp.gatsbyImageData}
                            alt={frontmatter.series.title}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default PodcastHeader;